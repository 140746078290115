import { validateRequiredNaturalNumber, validateNullableNaturalNumber } from '@flyward/platform/helpers/inputValidators'
import { type AirframeCheckType, type MaintenanceReserveCollectionType, type UtilizationUnits } from '@flyward/platform/models'
import { z } from 'zod'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Airframe.AirframeCheckDto
 */
export interface AirframeCheckDto {
  checkId: string
  checkType: AirframeCheckType
  dateAtLastQualifyingEvent?: string
  mrRateAmount?: number
  mrRateYear?: number
  mrCurrentFund?: number
  mrRateType?: UtilizationUnits
  mrCollectionType?: MaintenanceReserveCollectionType
  monthsSinceEventAtContractDelivery?: number
}

export const AirframeCheckDtoSchema = z.object({
  checkId: z.string().optional().nullable(),
  checkType: validateRequiredNaturalNumber('Check Type'),
  dateAtLastQualifyingEvent: z.string().optional().nullable(),
  mrRateAmount: validateNullableNaturalNumber,
  mrRateYear: validateNullableNaturalNumber,
  mrCurrentFund: validateNullableNaturalNumber,
  mrRateType: validateNullableNaturalNumber,
  mrCollectionType: validateNullableNaturalNumber,
  monthsSinceEventAtContractDelivery: validateNullableNaturalNumber,
})
