import { AuthorizedElement } from '@flyward/appIdentity/context/AuthorizedRole/AuthorizedElement'
import { useAssetComponentsForms } from '@flyward/assets/context'
import { Button, ButtonVariant, IconVariant, Size } from '@flyward/platform/components'
import queryString from 'query-string'
import { useNavigate, useParams } from 'react-router-dom'

export const StandaloneEnginePageHeader = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { hasAnyChanges, isAllValid, isInEditMode, setIsInEditMode, getAllFormRefs } = useAssetComponentsForms()

  const allFormRefs = getAllFormRefs()

  const submitAllForms = () => {
    allFormRefs.forEach((formRef) => {
      if (formRef.submitRef?.current !== null) {
        formRef.submitRef.current.click()
      }
    })
  }

  return (
    <div className="absolute right-0 top-0 mr-4 mt-4 flex gap-1">
      {!isInEditMode && !hasAnyChanges && (
        <Button
          variant={ButtonVariant.Primary}
          size={Size.Medium}
          leftIcon={IconVariant.FlightTakeOff}
          label="Fly"
          labelClassName={`hidden group-hover:block`}
          onClick={() => {
            // TODO @Dan capture this
            navigate(`/flyforward/?${queryString.stringify({ assetIds: [id] })}`)
          }}
        />
      )}
      <AuthorizedElement>
        <div data-permission-element-id="edit-asset">
          {!isInEditMode && (
            <div>
              <Button
                variant={ButtonVariant.Primary}
                size={Size.Medium}
                leftIcon={IconVariant.Edit}
                label="Edit"
                key={'edit-engine'}
                labelClassName={`hidden group-hover:block`}
                onClick={() => {
                  setIsInEditMode(true)
                }}
              />
            </div>
          )}
          {hasAnyChanges && (
            <div>
              <Button
                variant={ButtonVariant.Primary}
                disabled={!isAllValid}
                size={Size.Medium}
                leftIcon={IconVariant.Save}
                key={'update-engine'}
                data-testid="saveAsset"
                label="Save"
                labelClassName={`hidden group-hover:block`}
                onClick={() => {
                  submitAllForms()
                  setIsInEditMode(false)
                }}
              />
            </div>
          )}
        </div>
      </AuthorizedElement>
    </div>
  )
}
