import { type RowModel } from '@tanstack/react-table'
import { type VerifyLlpResultDto } from '../../../../../../../models/aircraftComponents/aircraftEngine/verify/VerifyLlpResultDto'
import { KbLLpRow } from './KbLLpRow'

interface IKbLLpRowProps {
  rowModel: RowModel<VerifyLlpResultDto>
}

const KbLLpRows = ({ rowModel }: IKbLLpRowProps) => {
  return (
    <tbody>
      {rowModel.rows.map((llpResult, index) => (
        <KbLLpRow key={index} llpResult={llpResult} />
      ))}
    </tbody>
  )
}

export { KbLLpRows }
