import { type EnvironmentalImpactType } from '@flyward/platform'
import { validateNullableDate, validateRequiredDate, validateNullableNaturalNumber } from '@flyward/platform/helpers/inputValidators'
import { z } from 'zod'

export interface StandAloneEngineDTO {
  leaseStartDate: string
  leaseEndDate: string
  dateOfLastTechSpec: string
  lessee: string
  lesseeHabitualBase: string
  operatingEnvironment: EnvironmentalImpactType
  region: string
  portfolio: string
  averageFhFcRatio: number
  averageMonthlyFlightHours: number
  averageMonthlyFlightCycles: number
}

export const StandaloneEngineDtoSchema = z.object({
  leaseStartDate: validateNullableDate,
  leaseEndDate: validateNullableDate,
  dateOfLastTechSpec: validateRequiredDate('Date of Last Tech Spec'),
  lessee: z.string().optional().nullable(),
  lesseeHabitualBase: z.string().optional().nullable(),
  operatingEnvironment: validateNullableNaturalNumber,
  region: z.string().optional().nullable(),
  portfolio: z.string().optional().nullable(),
  averageFhFcRatio: validateNullableNaturalNumber,
  averageMonthlyFlightHours: validateNullableNaturalNumber,
  averageMonthlyFlightCycles: validateNullableNaturalNumber,
})
