/* eslint-disable @typescript-eslint/no-misused-promises */
import { AuthorizedElement } from '@flyward/appIdentity'
import { Switch } from '@flyward/platform'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError, showSuccess } from '@flyward/platform/services'
import { isNil } from 'lodash'
import { useState } from 'react'

interface IChangeStateProps {
  maintenanceId: string
  checked: boolean
  onChangeState: (assetId: string) => Promise<string | undefined>
}

export const ChangeState = ({ maintenanceId, checked, onChangeState }: IChangeStateProps) => {
  const [switchChecked, setSwitchChecked] = useState<boolean>(checked)

  const changeSwitch = async () => {
    setSwitchChecked(!switchChecked)
    const result = await onChangeState(maintenanceId)
    if (isNil(result)) {
      showSuccess(`Status change on maintenance program was successful!`)
    } else {
      showError(formatAxiosErrorMessage(result))
    }
  }

  return (
    <AuthorizedElement>
      <Switch data-permission-element-id="change-state-any-maintenance-program" checked={switchChecked} onCheckedChange={changeSwitch} />
    </AuthorizedElement>
  )
}
