import { type ComponentUtilizationAtEventDto, ComponentUtilizationAtEventDtoSchema, type CheckType } from '@flyward/platform/models'
import { AirframeContractSchema, type AirframeContractDto } from './AirframeContractDto'
import { z } from 'zod'
import { validateRequiredNaturalNumber } from '@flyward/platform/helpers/inputValidators'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Airframe.NewAirframeCheckDto
 */
export interface NewAirframeCheckDto {
  checkType: CheckType
  contract: AirframeContractDto
  previousEvents: ComponentUtilizationAtEventDto[]
}

export const NewAirframeCheckDtoSchema = z.object({
  checkType: validateRequiredNaturalNumber('Check Type'),
  contract: AirframeContractSchema,
  previousEvents: z.array(ComponentUtilizationAtEventDtoSchema).optional().nullable(),
})
