import { Role, type LoggedInUserDto } from '@flyward/platform'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'

const routes = new Map<Role, string[]>([
  [Role.Admin, ['user-management', 'fleet', 'flyforward', 'reports', 'knowledge-base', 'test']],
  [Role.NormalUser, ['fleet', 'flyforward', 'reports', 'knowledge-base', 'test']],
  [Role.SuperUser, ['fleet', 'flyforward', 'reports', 'knowledge-base', 'test']],
  [Role.None, []],
])

const isUserAuthorized = (loggedUser: LoggedInUserDto, path: string): boolean => {
  const allUserRoutes = routes.get(loggedUser.role)
  if (isArrayEmptyOrNull(allUserRoutes)) {
    return false
  }

  const sanitizedPath = path.split('/')[1]
  for (const route of allUserRoutes!) {
    if (route === sanitizedPath) {
      return true
    }
  }

  return false
}

export { isUserAuthorized }
