export enum EnvironmentalImpactType {
  Benign = 1,
  Moderate = 2,
  Harsh = 3,
  VeryHarsh = 4,
  Severe = 5,
}

export const EnvironmentalImpactTypeDisplay = (value: EnvironmentalImpactType) => {
  switch (value) {
    case EnvironmentalImpactType.Benign:
      return 'Benign'
    case EnvironmentalImpactType.Moderate:
      return 'Moderate'
    case EnvironmentalImpactType.Harsh:
      return 'Harsh'
    case EnvironmentalImpactType.VeryHarsh:
      return 'Very Harsh'
    case EnvironmentalImpactType.Severe:
      return 'Severe'
  }
}

export const EnvironmentalImpactTypeOptions = [
  { value: (EnvironmentalImpactType.Benign as number).toString(), label: EnvironmentalImpactTypeDisplay(EnvironmentalImpactType.Benign) },
  { value: (EnvironmentalImpactType.Moderate as number).toString(), label: EnvironmentalImpactTypeDisplay(EnvironmentalImpactType.Moderate) },
  { value: (EnvironmentalImpactType.Harsh as number).toString(), label: EnvironmentalImpactTypeDisplay(EnvironmentalImpactType.Harsh) },
  {
    value: (EnvironmentalImpactType.VeryHarsh as number).toString(),
    label: EnvironmentalImpactTypeDisplay(EnvironmentalImpactType.VeryHarsh),
  },
  {
    value: (EnvironmentalImpactType.Severe as number).toString(),
    label: EnvironmentalImpactTypeDisplay(EnvironmentalImpactType.Severe),
  },
]
