import { type RowModel } from '@tanstack/react-table'
import { KbAirframeCheckRow } from './KbAirframeCheckRow'
import { type VerifyAirframeCheckResultDto } from '../../../../../../../models/aircraftComponents/airframe/verify/VerifyAirframeCheckResultDto'

interface IKbLLpRowProps {
  rowModel: RowModel<VerifyAirframeCheckResultDto>
}

const KbAirframeCheckRows = ({ rowModel }: IKbLLpRowProps) => {
  return (
    <tbody>
      {rowModel.rows.map((llpResult, index) => (
        <KbAirframeCheckRow key={index} llpResult={llpResult} />
      ))}
    </tbody>
  )
}

export { KbAirframeCheckRows }
