import { useReactTable, getCoreRowModel } from '@tanstack/react-table'
import { KbLlpsHeader } from './KbLlpsHeader'
import { KbLLpRows } from './KbLLpRows'
import { type VerifyLlpResultDto } from '../../../../../../../models/aircraftComponents/aircraftEngine/verify/VerifyLlpResultDto'
import { readOnlyColumns } from './columns'
import { useState } from 'react'
import { type RowSizeMap } from '@flyward/platform/components'

interface IAssetAlignmentWizardProps {
  llpResults: VerifyLlpResultDto[]
}

export const KbLlpsTable = ({ llpResults }: IAssetAlignmentWizardProps) => {
  const [columnSizes, setColumnSizes] = useState<RowSizeMap>({
    llpProgram_module: { defaultSize: 80, minSize: 50, maxSize: 300, currentSize: 80 },
    llpProgram_model: { defaultSize: 200, minSize: 50, maxSize: 400, currentSize: 200 },
    llpProgram_limit: { defaultSize: 80, minSize: 50, maxSize: 200, currentSize: 80 },
    llpProgram_cost: { defaultSize: 110, minSize: 50, maxSize: 200, currentSize: 110 },
  })

  const table = useReactTable<VerifyLlpResultDto>({
    data: llpResults,
    columns: readOnlyColumns(columnSizes),
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
  })

  return (
    <table className="max-w-fit basis-4/12 table-auto border-collapse border border-black-20">
      <KbLlpsHeader headerGroups={table.getHeaderGroups()} columnSizes={columnSizes} setColumnSizes={setColumnSizes} />
      <KbLLpRows rowModel={table.getRowModel()} />
    </table>
  )
}
