import { type ZitadelAuth } from '@zitadel/react'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { clearZitadelUserInformation } from '@flyward/platform/store'
import { useAppDispatch } from '@flyward/platform/store/configureHooks'
import { ZitadelInstance, removePersistedZitadelUserAccessToken } from '@flyward/platform/services'

export const AuthFailed = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const zitadel = useRef<ZitadelAuth>(ZitadelInstance.getInstance())
  navigate('/home/login')

  useEffect(() => {
    zitadel.current.signout().then(() => {
      removePersistedZitadelUserAccessToken()
      dispatch(clearZitadelUserInformation())
    })
  }, [dispatch])
  return <></>
}
