import { type RowSizeMap, SimpleHeader } from '@flyward/platform/components'
import { type ColumnDef } from '@tanstack/react-table'

import { type DraggableAirframeCheck } from '../../../../../../../models/aircraftComponents'

const editableColumns = (columnSizes: RowSizeMap): Array<ColumnDef<DraggableAirframeCheck>> => [
  {
    accessorKey: 'airframeCheck.checkType',
    header: () => {
      return (
        <div
          className="flex items-center justify-center"
          style={{
            width: columnSizes.airframeCheck_checkType?.currentSize ?? 0,
            maxWidth: columnSizes.airframeCheck_checkType?.currentSize ?? 0,
          }}
        >
          <SimpleHeader title="Check Type" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.airframeCheck_checkType?.defaultSize ?? 0,
    minSize: columnSizes.airframeCheck_checkType?.minSize ?? 0,
    maxSize: columnSizes.airframeCheck_checkType?.maxSize ?? 0,
  },
  {
    accessorKey: 'airframeCheck.dateAtLastQualifyingEvent',
    header: () => {
      return (
        <div
          className="flex items-center justify-center"
          style={{
            width: columnSizes.airframeCheck_dateAtLastQualifyingEvent?.currentSize ?? 0,
            maxWidth: columnSizes.airframeCheck_dateAtLastQualifyingEvent?.currentSize ?? 0,
          }}
        >
          <SimpleHeader title="Date @ last check" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.airframeCheck_dateAtLastQualifyingEvent?.defaultSize ?? 0,
    minSize: columnSizes.airframeCheck_dateAtLastQualifyingEvent?.minSize ?? 0,
    maxSize: columnSizes.airframeCheck_dateAtLastQualifyingEvent?.maxSize ?? 0,
  },
  {
    accessorKey: 'airframeCheck.mrRateAmount',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes.airframeCheck_mrRateAmount?.currentSize ?? 0 }}>
          <SimpleHeader title="MR Rate" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.airframeCheck_mrRateAmount?.defaultSize ?? 0,
    minSize: columnSizes.airframeCheck_mrRateAmount?.minSize ?? 0,
    maxSize: columnSizes.airframeCheck_mrRateAmount?.maxSize ?? 0,
  },
  {
    accessorKey: 'airframeCheck.mrRateType',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes.airframeCheck_mrRateType?.currentSize ?? 0 }}>
          <SimpleHeader title="MR rate type" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.airframeCheck_mrRateType?.defaultSize ?? 0,
    minSize: columnSizes.airframeCheck_mrRateType?.minSize ?? 0,
    maxSize: columnSizes.airframeCheck_mrRateType?.maxSize ?? 0,
  },
  {
    accessorKey: 'airframeCheck.mrRateYear',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes.airframeCheck_mrRateYear?.currentSize ?? 0 }}>
          <SimpleHeader title="MR rate year" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.airframeCheck_mrRateYear?.defaultSize ?? 0,
    minSize: columnSizes.airframeCheck_mrRateYear?.minSize ?? 0,
    maxSize: columnSizes.airframeCheck_mrRateYear?.maxSize ?? 0,
  },
  {
    accessorKey: 'airframeCheck.mrCollectionType',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes.airframeCheck_mrCollectionType?.currentSize ?? 0 }}>
          <SimpleHeader title="MR collection type" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.airframeCheck_mrCollectionType?.defaultSize ?? 0,
    minSize: columnSizes.airframeCheck_mrCollectionType?.minSize ?? 0,
    maxSize: columnSizes.airframeCheck_mrCollectionType?.maxSize ?? 0,
  },
  {
    accessorKey: 'airframeCheck.mrCurrentFund',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes.airframeCheck_mrCurrentFund?.currentSize ?? 0 }}>
          <SimpleHeader title="MR fund" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.airframeCheck_mrCurrentFund?.defaultSize ?? 0,
    minSize: columnSizes.airframeCheck_mrCurrentFund?.minSize ?? 0,
    maxSize: columnSizes.airframeCheck_mrCurrentFund?.maxSize ?? 0,
  },
  {
    accessorKey: 'airframeCheck.monthsSinceEventAtContractDelivery',
    header: () => {
      return (
        <div
          className="flex items-center justify-center"
          style={{ width: columnSizes.airframeCheck_monthsSinceEventAtContractDelivery?.currentSize ?? 0 }}
        >
          <SimpleHeader
            title="Months since check at contract delivery"
            className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0"
          />
        </div>
      )
    },
    size: columnSizes.airframeCheck_monthsSinceEventAtContractDelivery?.defaultSize ?? 0,
    minSize: columnSizes.airframeCheck_monthsSinceEventAtContractDelivery?.minSize ?? 0,
    maxSize: columnSizes.airframeCheck_monthsSinceEventAtContractDelivery?.maxSize ?? 0,
  },
  {
    accessorKey: 'alignmentStatus',
    header: () => <div className="flex items-center justify-center" style={{ width: columnSizes.alignmentStatus?.currentSize ?? 0 }} />,
    size: columnSizes.alignmentStatus?.defaultSize ?? 0,
    minSize: columnSizes.alignmentStatus?.minSize ?? 0,
    maxSize: columnSizes.alignmentStatus?.maxSize ?? 0,
    enableResizing: false,
  },
  {
    accessorKey: 'order',
    header: () => <div className="flex items-center justify-center" style={{ width: columnSizes.order?.currentSize ?? 0 }} />,
    size: columnSizes.order?.defaultSize ?? 0,
    minSize: columnSizes.order?.minSize ?? 0,
    maxSize: columnSizes.order?.maxSize ?? 0,
    enableResizing: false,
  },
  {
    accessorKey: 'align',
    header: () => <div className="flex items-center justify-center" style={{ width: columnSizes.align?.currentSize ?? 0 }} />,
    size: columnSizes.align?.defaultSize ?? 0,
    minSize: columnSizes.align?.minSize ?? 0,
    maxSize: columnSizes.align?.maxSize ?? 0,
    enableResizing: false,
  },
]
export { editableColumns }
