import { type AxiosResponse } from 'axios'
import { isNil } from 'lodash'
import React from 'react'
import { GenericErrorDisplay, ValidationErrorDisplay, type IValidationErrorDisplay } from './ErrorDisplay'

/**
 * https://flyward.atlassian.net/wiki/spaces/MFM/pages/262995970/Api+Status+Codes+and+Error+Handling
 * @param jsonErrorResponse
 * @returns
 */
const formatAxiosErrorMessage = (jsonErrorResponse: string | undefined, displayErrorMessage?: React.ReactNode): React.ReactNode => {
  if (isNil(jsonErrorResponse)) {
    return <div>Unknown error</div>
  }

  const axiosErrorResponse: AxiosResponse = JSON.parse(jsonErrorResponse)

  console.error('Axios Error:', axiosErrorResponse)

  if (axiosErrorResponse.status === 400) {
    const errorResponse = axiosErrorResponse.data as IValidationErrorDisplay
    return <ValidationErrorDisplay errors={errorResponse.errors} />
  }

  return <GenericErrorDisplay errorCode={axiosErrorResponse.status} displayErrorMessage={displayErrorMessage} />
}

export { formatAxiosErrorMessage }
