import type { StandaloneEngineMaintenancePrograms } from '@flyward/assets/models'
import { TextCellWithLabel } from '@flyward/platform'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { isNil } from 'lodash'

export const ReadOnlyEngineMaintenanceProgram = ({ assignedEngineProgramId, availablePrograms }: StandaloneEngineMaintenancePrograms) => {
  const getEngineProgramName = (id: string | undefined) => {
    if (isNil(id) || isArrayEmptyOrNull(availablePrograms.availableEnginePrograms)) {
      return ''
    }
    const index = availablePrograms.availableEnginePrograms.findIndex((prg) => prg.value === id)
    return availablePrograms.availableEnginePrograms[index]?.label
  }
  return (
    <div className="flex w-full gap-x-6">
      <TextCellWithLabel info={getEngineProgramName(assignedEngineProgramId)} label={'Assign Engine Program'} />{' '}
      <div className="mr-12 basis-2/6"></div>
    </div>
  )
}
