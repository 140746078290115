import { NumberDisplay, NumberDisplayType, type RowSizeMap, SimpleHeader } from '@flyward/platform/components'
import { type VerifyLlpResultDto } from '../../../../../../../models/aircraftComponents/aircraftEngine/verify/VerifyLlpResultDto'
import { type ColumnDef } from '@tanstack/react-table'

const readOnlyColumns = (columnSizes: RowSizeMap): Array<ColumnDef<VerifyLlpResultDto>> => [
  {
    accessorKey: 'llpProgram.module',
    header: () => (
      <div
        className="flex items-center justify-center"
        style={{
          width: columnSizes.llpProgram_module?.currentSize ?? 0,
          maxWidth: columnSizes.llpProgram_module?.currentSize ?? 0,
        }}
      >
        <SimpleHeader title="Module" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
      </div>
    ),
    cell: ({ row }) => (
      <div
        className="flex items-center justify-center pt-1"
        style={{
          width: columnSizes.llpProgram_module?.currentSize ?? 0,
          maxWidth: columnSizes.llpProgram_module?.currentSize ?? 0,
        }}
      >
        {row.original.llpProgram?.module}
      </div>
    ),
    size: columnSizes.llpProgram_module?.defaultSize ?? 0,
    minSize: columnSizes.llpProgram_module?.minSize ?? 0,
    maxSize: columnSizes.llpProgram_module?.maxSize ?? 0,
  },
  {
    accessorKey: 'llpProgram.model',
    header: () => (
      <div
        className="flex items-center justify-center"
        style={{
          width: columnSizes.llpProgram_model?.currentSize ?? 0,
          maxWidth: columnSizes.llpProgram_model?.currentSize ?? 0,
        }}
      >
        <SimpleHeader title="Description" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
      </div>
    ),
    cell: ({ row }) => (
      <div
        className="w-full min-w-full truncate pt-1 text-xs"
        style={{
          width: columnSizes.llpProgram_model?.currentSize ?? 0,
          maxWidth: columnSizes.llpProgram_model?.currentSize ?? 0,
        }}
      >
        {row.original.llpProgram?.model}
      </div>
    ),
    size: columnSizes.llpProgram_model?.defaultSize ?? 0,
    minSize: columnSizes.llpProgram_model?.minSize ?? 0,
    maxSize: columnSizes.llpProgram_model?.maxSize ?? 0,
  },
  {
    accessorKey: 'llpProgram.limit',
    header: () => (
      <div
        className="flex items-center justify-center"
        style={{
          width: columnSizes.llpProgram_limit?.currentSize ?? 0,
          maxWidth: columnSizes.llpProgram_limit?.currentSize ?? 0,
        }}
      >
        <SimpleHeader title="Limit" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
      </div>
    ),
    size: columnSizes.llpProgram_limit?.defaultSize ?? 0,
    minSize: columnSizes.llpProgram_limit?.minSize ?? 0,
    maxSize: columnSizes.llpProgram_limit?.maxSize ?? 0,
    cell: ({ row }) => (
      <div
        className="w-full min-w-full pt-1 text-xs"
        style={{
          width: columnSizes.llpProgram_limit?.currentSize ?? 0,
          maxWidth: columnSizes.llpProgram_limit?.currentSize ?? 0,
        }}
      >
        <NumberDisplay displayType={NumberDisplayType.Decimal} currencyDecimals={2} value={row.original.llpProgram?.limit} />
      </div>
    ),
  },
  {
    accessorKey: 'llpProgram.cost',
    header: () => (
      <div
        className="flex items-center justify-center"
        style={{
          width: columnSizes.llpProgram_cost?.currentSize ?? 0,
          maxWidth: columnSizes.llpProgram_cost?.currentSize ?? 0,
        }}
      >
        <SimpleHeader title="CLP" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
      </div>
    ),
    size: columnSizes.llpProgram_cost?.defaultSize ?? 0,
    minSize: columnSizes.llpProgram_cost?.minSize ?? 0,
    maxSize: columnSizes.llpProgram_cost?.maxSize ?? 0,
    cell: ({ row }) => (
      <div className="w-full min-w-full pt-1 text-xs">
        <NumberDisplay displayType={NumberDisplayType.Currency} currencyDecimals={2} value={row.original.llpProgram?.cost} />
      </div>
    ),
  },
]

export { readOnlyColumns }
