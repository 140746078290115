import { FullPageLoadingSpinner, SpinnerStateContextProvider, useSpinnerState } from '@flyward/platform'
import { NotificationServiceInitializer } from '@flyward/platform/services'
import { useEffect } from 'react'
import { RouterProvider, StoreProvider } from './providers'

export const App = () => {
  const { isShowing } = useSpinnerState()

  useEffect(() => {
    console.info('WebUI version', __WEBUI_VERSION__)
    console.info('WebUI CI current env:', __WEBUI_CI_ENV__, VITE_CI_ENV)
  }, [])

  return (
    <StoreProvider>
      <SpinnerStateContextProvider>
        <div className={'flex h-screen max-h-screen flex-col justify-between overflow-hidden bg-background-light'}>
          <RouterProvider />
          <NotificationServiceInitializer />
          <FullPageLoadingSpinner isShowing={isShowing} secondsSinceLoading={0} hideSpinner={() => {}} />
        </div>
      </SpinnerStateContextProvider>
    </StoreProvider>
  )
}
