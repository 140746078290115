import type { AircraftMaintenancePrograms } from '@flyward/assets/models'
import { TextCellWithLabel } from '@flyward/platform'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { isNil } from 'lodash'

export const ReadOnlyMaintenanceProgram = ({
  assignedAirframeProgramId,
  assignedAPUProgramId,
  assignedEngineProgramId,
  assignedLandingGearProgramId,
  availablePrograms,
}: AircraftMaintenancePrograms) => {
  const getAirframeProgramName = (id: string | undefined) => {
    if (isNil(id) || isArrayEmptyOrNull(availablePrograms.availableAirframePrograms)) {
      return ''
    }
    const index = availablePrograms.availableAirframePrograms.findIndex((prg) => prg.value === id)
    return availablePrograms.availableAirframePrograms[index]?.label
  }
  const getEngineProgramName = (id: string | undefined) => {
    if (isNil(id) || isArrayEmptyOrNull(availablePrograms.availableEnginePrograms)) {
      return ''
    }
    const index = availablePrograms.availableEnginePrograms.findIndex((prg) => prg.value === id)
    return availablePrograms.availableEnginePrograms[index]?.label
  }
  const getLandingGearProgramName = (id: string | undefined) => {
    if (isNil(id) || isArrayEmptyOrNull(availablePrograms.availableLandingGearPrograms)) {
      return ''
    }
    const index = availablePrograms.availableLandingGearPrograms.findIndex((prg) => prg.value === id)
    return availablePrograms.availableLandingGearPrograms[index]?.label
  }
  const getApuProgramNames = (id: string | undefined) => {
    if (isNil(id) || isArrayEmptyOrNull(availablePrograms.availableAuxiliaryPowerUnitPrograms)) {
      return ''
    }
    const index = availablePrograms.availableAuxiliaryPowerUnitPrograms.findIndex((prg) => prg.value === id)
    return availablePrograms.availableAuxiliaryPowerUnitPrograms[index]?.label
  }
  return (
    <div className="flex w-full gap-x-6">
      <TextCellWithLabel label="Airframe Program" info={getAirframeProgramName(assignedAirframeProgramId)} className="basis-3/12" />
      <TextCellWithLabel label="Engine Program" info={getEngineProgramName(assignedEngineProgramId)} className="basis-3/12" />
      <TextCellWithLabel label="Landing Gear Program" info={getLandingGearProgramName(assignedLandingGearProgramId)} className="basis-3/12" />
      <TextCellWithLabel label="APU Program" info={getApuProgramNames(assignedAPUProgramId)} className="basis-3/12" />
    </div>
  )
}
