import { CnFormControl, CnFormField, CnFormItem, CnFormMessage } from '@flyward/platform/components/Form'
import { Input, InputType } from '@flyward/platform/components/Input'
import { cn } from '@flyward/platform/components/utils'
import { isEmpty } from 'lodash'
import { type FieldValues, type Control, type Path } from 'react-hook-form'

interface IEditableInputCellWithLabelWithLabel<TFormData extends FieldValues> {
  inputType?: InputType
  label?: string
  className?: string
  labelClassName?: string
  inputClassName?: string
  formControl: Control<TFormData, unknown>
  fieldName: Path<TFormData>
  hasAutoFocus?: boolean
  preventInfo?: boolean
  showErrorAsTooltip?: boolean
  afterChange?: (newValue: string) => void
  isReadOnly?: boolean
}

const EditableInputCellWithLabelWithLabel = <TFormData extends FieldValues>({
  inputType = InputType.SafeText,
  label = '',
  className = '',
  inputClassName = '',
  formControl,
  fieldName,
  labelClassName = '',
  hasAutoFocus = false,
  preventInfo = false,
  showErrorAsTooltip = false,
  isReadOnly = false,
  afterChange = () => {},
}: IEditableInputCellWithLabelWithLabel<TFormData>) => {
  return (
    <div className={(cn('flex flex-col justify-center gap-y-1'), className)}>
      {!isEmpty(label) && <em className={cn('block text-xs font-semibold capitalize not-italic text-text-3', labelClassName)}>{label}</em>}
      {!preventInfo ? (
        <CnFormField
          control={formControl}
          name={fieldName}
          render={({ field }) => {
            return (
              <CnFormItem className="relative">
                <CnFormControl>
                  <Input
                    disabled={isReadOnly}
                    hasAutoFocus={hasAutoFocus}
                    inputClassName={cn('h-5 w-full', inputClassName)}
                    type={inputType}
                    controlledValue={field.value}
                    showErrorMessage={false}
                    setRawValueOnBlur={(value) => {
                      field.onChange(value)
                      afterChange(value)
                    }}
                  />
                </CnFormControl>
                <CnFormMessage showAsTooltip={showErrorAsTooltip} />
              </CnFormItem>
            )
          }}
        />
      ) : (
        <span className="text-sm">N/A</span>
      )}
    </div>
  )
}

export { EditableInputCellWithLabelWithLabel }
export type { IEditableInputCellWithLabelWithLabel }
