import { type BaseEntityIdentifiable } from '@flyward/platform'
import { validateNullablePositiveDecimalNumber } from '@flyward/platform/helpers/inputValidators'
import { z } from 'zod'

export interface Inflation extends BaseEntityIdentifiable {
  maintenanceProgramId: string
  referenceYear: number
  percentage: number
}

export const InflationSchema = z.object({
  id: z.any().optional().nullable(),
  maintenanceProgramId: z.any().optional().nullable(),
  referenceYear: z.any().optional().nullable(),
  percentage: validateNullablePositiveDecimalNumber,
})
