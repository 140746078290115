import { useEffect } from 'react'
import { useNotifications } from '@toolpad/core/useNotifications'
import { setNotificationsInstance } from './NotificationsService'

export const NotificationServiceInitializer = () => {
  const notifications = useNotifications()

  useEffect(() => {
    setNotificationsInstance(notifications)
  }, [notifications])

  return null
}
