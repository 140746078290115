import {
  validateNullableDate,
  validateNullableNaturalNumber,
  validateRequiredNaturalNumber,
  validateRequiredString,
} from '../../../helpers/inputValidators'
import { type CheckType } from '../../enums/CheckType'
import { z } from 'zod'

/**
 * Cloned from from API: Platform.Core.Entity.DTOs.Utilization.ComponentUtilizationAtEventDto
 */
export interface ComponentUtilizationAtEventDto {
  eventType: CheckType
  checkId: string
  totalHoursSinceNewAtLastQualifyingEvent?: number
  cyclesSinceNewAtLastQualifyingEvent?: number
  dateAtLastQualifyingEvent?: string
}

export const ComponentUtilizationAtEventDtoSchema = z.object({
  eventType: validateRequiredNaturalNumber('Check Type'),
  checkId: validateRequiredString('Check Id'),
  totalHoursSinceNewAtLastQualifyingEvent: validateNullableNaturalNumber,
  cyclesSinceNewAtLastQualifyingEvent: validateNullableNaturalNumber,
  dateAtLastQualifyingEvent: validateNullableDate,
})
