import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragOverEvent,
} from '@dnd-kit/core'
import { arraySwap, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { AlignmentStatus, type RowSizeMap } from '@flyward/platform'
import { isNil } from 'lodash'
import { useState } from 'react'
import { type Control } from 'react-hook-form'
import { type DraggableLlp, type DraggableLlpStack } from '../../../../../../../models'
import { type VerifyLlpResultDto } from '../../../../../../../models/aircraftComponents/aircraftEngine/verify/VerifyLlpResultDto'
import { AssetLlpRow } from './AssetLlpRow'
import { type RowModel } from '@tanstack/react-table'

interface IAssetLlpsRowsProps {
  rowModel: RowModel<DraggableLlp>
  verifyLLPsResult: VerifyLlpResultDto[]
  componentFormValues: DraggableLlpStack
  formControl: Control<DraggableLlpStack, unknown>
  onDeleteExistingLlp: (llpId: string) => void
  onExistingLlpCopyFromKb: (positionalIndex: number, llp: DraggableLlp) => void
  persistAssetLllStackInForm: (llpStack: DraggableLlp[]) => void
  kbTotalCount: number
  columnSizes: RowSizeMap
}

const AssetLlpsRows = ({
  verifyLLPsResult,
  componentFormValues,
  formControl,
  onDeleteExistingLlp,
  onExistingLlpCopyFromKb,
  persistAssetLllStackInForm,
  kbTotalCount,
  columnSizes,
}: IAssetLlpsRowsProps) => {
  const assetLlps: DraggableLlp[] = componentFormValues.llps
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null)
  const [overIndex, setOverIndex] = useState<number | null>(null)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragCancel = () => {
    setDraggedIndex(null)
    setOverIndex(null)
  }

  const handleDragStart = (event: DragEndEvent) => {
    setDraggedIndex(Number(event.active.id))
  }

  const handleDragOver = (event: DragOverEvent) => {
    const overId = event.over?.id
    if (!isNil(overId)) {
      setOverIndex(Number(overId))
    }
  }

  const updateLlpsStatus = ({
    reorderedBackingFields,
    fromIndex,
    toIndex,
  }: {
    reorderedBackingFields: DraggableLlp[]
    fromIndex: number
    toIndex: number
  }): void => {
    const successStatuses = [AlignmentStatus.Success, AlignmentStatus.SuggestedAlignment]

    const fromLlp = assetLlps[fromIndex]
    const toLlp = assetLlps[toIndex]
    const verifyFromLLP = verifyLLPsResult[fromIndex]
    const verifyToLLP = verifyLLPsResult[toIndex]

    const fromStatus = fromLlp.alignmentStatus
    const toStatus = toLlp.alignmentStatus

    const fromLlpMatchesKb = !isNil(verifyToLLP?.llpProgram?.model) && fromLlp?.llp?.componentModel === verifyToLLP?.llpProgram?.model
    const toLlpMatchesKb = !isNil(verifyFromLLP?.llpProgram?.model) && toLlp?.llp?.componentModel === verifyFromLLP?.llpProgram?.model

    /**
     * IF Llp is placed in the correct position(matches KB Llp description) -> set it to success
     * ELSE
     *    IF Llp already placed correctly or SuggestedAlignment, when moved to another position -> set it to ToAlignManually
     *    ELSE -> leave the status as it is because the entire LLP row is being swapped and statuses will be switched anyways by arraySwap()
     */

    if (fromLlpMatchesKb) {
      reorderedBackingFields[fromIndex].alignmentStatus = AlignmentStatus.Success
    } else {
      reorderedBackingFields[fromIndex].alignmentStatus = successStatuses.includes(fromStatus) ? AlignmentStatus.ToAlignManually : fromStatus
    }

    if (toLlpMatchesKb) {
      reorderedBackingFields[toIndex].alignmentStatus = AlignmentStatus.Success
    } else {
      reorderedBackingFields[toIndex].alignmentStatus = successStatuses.includes(toStatus) ? AlignmentStatus.ToAlignManually : toStatus
    }
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      const fromIndex = Number(active.id)
      const toIndex = Number(over?.id)

      let reorderedAssetLlps = assetLlps

      updateLlpsStatus({ reorderedBackingFields: reorderedAssetLlps, fromIndex, toIndex })

      // swap statuses by default
      reorderedAssetLlps = arraySwap(assetLlps, fromIndex, toIndex)

      persistAssetLllStackInForm(reorderedAssetLlps)
    }
    setDraggedIndex(null)
    setOverIndex(null)
  }

  const DraggedElement = ({ draggedIndex }: { draggedIndex: number }) => {
    const formLlp = assetLlps[Number(draggedIndex)]
    const llpStatus = formLlp.alignmentStatus
    const pathPrefix = `llps.${draggedIndex}.llp`

    return (
      <div className="-mt-1 opacity-100">
        <AssetLlpRow
          kbTotalCount={kbTotalCount}
          positionalIndex={Number(draggedIndex)}
          formLlp={assetLlps[Number(draggedIndex)]}
          llpStatus={llpStatus}
          formControl={formControl}
          onDeleteExistingLlp={onDeleteExistingLlp}
          onExistingLlpCopyFromKb={onExistingLlpCopyFromKb}
          pathPrefix={pathPrefix}
          columnSizes={columnSizes}
        />
      </div>
    )
  }

  return (
    <tbody>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        onDragOver={handleDragOver}
      >
        <SortableContext items={assetLlps.map((_item, index) => index)} strategy={verticalListSortingStrategy}>
          {assetLlps.map((formLlp, positionalIndex) => {
            const llpStatus = formLlp.alignmentStatus

            const pathPrefix = `llps.${positionalIndex}.llp`

            return (
              <AssetLlpRow
                key={pathPrefix}
                kbTotalCount={kbTotalCount}
                positionalIndex={positionalIndex}
                formLlp={formLlp}
                llpStatus={llpStatus}
                formControl={formControl}
                onDeleteExistingLlp={onDeleteExistingLlp}
                onExistingLlpCopyFromKb={onExistingLlpCopyFromKb}
                pathPrefix={pathPrefix}
                draggedIndex={draggedIndex}
                overIndex={overIndex}
                columnSizes={columnSizes}
              />
            )
          })}
        </SortableContext>
        <DragOverlay>{draggedIndex !== null && <DraggedElement draggedIndex={draggedIndex} />}</DragOverlay>
      </DndContext>
    </tbody>
  )
}

export { AssetLlpsRows }
