/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useGetExcelExportQuery } from '@flyward/forecasts/store'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { Button, ButtonVariant, IconVariant } from '@flyward/platform/components'
import { useSpinnerState } from '@flyward/platform/contexts'
import { showError, showSuccess } from '@flyward/platform/services'
import { errorMessages } from '@flyward/platform'

interface IReportItemExcelExtractProps {
  reportId: string
  reportItems: Array<{ reportItemId: string; assetSerialNumber: string }>
}

export const ReportItemExcelExtract = ({ reportId, reportItems }: IReportItemExcelExtractProps) => {
  const [buttonPressed, setButtonPressed] = useState(false)
  const { showSpinner, hideSpinner } = useSpinnerState()

  const [reportItemIds, setReportItemIds] = useState<string[]>([])
  const { data, error } = useGetExcelExportQuery({ reportId, reportItemIds }, { skip: !buttonPressed })

  useEffect(() => {
    if (!isNil(data) && data.length > 0) {
      for (const d of data) {
        if (d.data instanceof Blob) {
          const url = window.URL.createObjectURL(d.data)
          const link = document.createElement('a')
          link.href = url
          link.download = `${getMSN(d.reportItemId)}.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        } else {
          throw new Error('MediaSource handling is not supported in this method.')
        }
      }
      showSuccess(
        <span>
          {data.length} Excel {data.length > 1 ? 'files' : 'file'} downloaded
        </span>,
      )
      setButtonPressed(false)
      hideSpinner()
    }
    if (!isNil(error)) {
      showError(errorMessages.reports.generateExcelError)
      setButtonPressed(false)
      hideSpinner()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  const handleDownload = () => {
    const ids = reportItems.map((asset) => asset.reportItemId).filter((id): id is string => id !== undefined)
    if (ids.length > 0) {
      setReportItemIds(ids)
      setButtonPressed(true)
      showSpinner()
    }
  }

  const getMSN = (reportItemId: string) => {
    const item = reportItems.find((obj) => obj.reportItemId === reportItemId)
    return !isNil(item) ? item.assetSerialNumber : 'report'
  }

  return (
    <Button
      key={`excel-extract-${reportId}`}
      variant={ButtonVariant.Secondary}
      leftIcon={IconVariant.ExcelExtract}
      className="mr-1 h-full px-3"
      onClick={handleDownload}
      disabled={buttonPressed}
    />
  )
}
