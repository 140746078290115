import { RedeliveryConditionsEditable } from '@flyward/assets/components/AircraftComponents/common'
import { getLabelContent } from '@flyward/assets/helpers/assetHelpers'
import { type AirframeDto } from '@flyward/assets/models'
import { type NewAirframeCheckDto } from '@flyward/assets/models/aircraftComponents/airframe/NewAirframeCheckDto'
import {
  AirframeCheckTypeOptions,
  type CheckType,
  EditableBooleanCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  GUID_EMPTY,
  InputType,
  type ISelectOption,
  MaintenanceReserveCollectionType,
  UtilizationUnits,
} from '@flyward/platform'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { type Row } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { type Control, type FormState, type UseFormRegister, type UseFormSetValue, type UseFormUnregister, type UseFormWatch } from 'react-hook-form'
import { AirframeChecksEditableTable } from './AirframeChecks/AirframeChecksEditableTable'
import { type AirframeCheckDisplayDTO } from './AirframeChecks/columns'

interface IAirframeContractualEditableTabProps {
  airframe: AirframeDto
  formControl: Control<AirframeDto, unknown>
  formState: FormState<AirframeDto>
  watchFields: UseFormWatch<AirframeDto>
  setFormValue: UseFormSetValue<AirframeDto>
  registerFormField: UseFormRegister<AirframeDto>
  unRegisterFormField: UseFormUnregister<AirframeDto>
}

export const AirframeContractualEditableTab = ({
  airframe,
  formControl,
  formState,
  setFormValue,
  registerFormField,
  unRegisterFormField,
}: IAirframeContractualEditableTabProps) => {
  const utilizationAtEvents = airframe.assetComponentUtilizationSnapshot.componentUtilizationAtEvents

  const maintenanceReserve = airframe.airframeContract.airframeMaintenanceReserveContract
  const rates = maintenanceReserve.airframeMaintenanceRates
  const minimumRedeliveryConditions = airframe.airframeContract.minimumReDeliveryConditions
  const contractDeliverySnapshots = airframe.airframeContract.contractDeliverySnapshots
  const [dynamicMrEolLabel, setDynamicMrEolLabel] = useState<string>('')

  useEffect(() => {
    setDynamicMrEolLabel(getLabelContent(maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive))
  }, [maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive])

  const existingAirframeChecks: AirframeCheckDisplayDTO[] = utilizationAtEvents.map((event) => {
    return {
      checkType: event.eventType,
      mrRates: rates.find((rate) => rate.checkType === event.eventType),
      contractDeliverySnapshots: contractDeliverySnapshots.filter((snapshot) => snapshot.checkType === event.eventType),
      componentUtilizationAtEvents: utilizationAtEvents.filter((utilization) => utilization.eventType === event.eventType),
    }
  })

  const newItems = airframe.addRemoveAirframeChecks?.addedItems ?? []
  const removedItemsIds = airframe.addRemoveAirframeChecks?.removedItemIds ?? []

  const newAirframeChecks: AirframeCheckDisplayDTO[] =
    newItems.map((newCheck) => {
      const mrRates = newCheck.contract.airframeMaintenanceReserveContract.airframeMaintenanceRates[0]
      mrRates.checkType = newCheck.checkType

      const contractDeliverySnapshots = newCheck.contract.contractDeliverySnapshots
      contractDeliverySnapshots.forEach((snapshot) => {
        snapshot.checkType = newCheck.checkType
      })

      const componentUtilizationAtEvents = newCheck.previousEvents

      componentUtilizationAtEvents.forEach((event) => {
        event.eventType = newCheck.checkType
      })

      return {
        checkType: newCheck.checkType,
        mrRates,
        contractDeliverySnapshots,
        componentUtilizationAtEvents,
      }
    }) ?? []

  const remainingOptions: ISelectOption[] = AirframeCheckTypeOptions.filter(
    (option) => !existingAirframeChecks?.some((item) => (item.checkType as number).toString() === option.value),
  )

  const onAddRow = () => {
    const checkType: number = remainingOptions[0].value as unknown as CheckType
    const newRow: NewAirframeCheckDto = {
      checkType,
      previousEvents: [
        {
          eventType: checkType,
          checkId: GUID_EMPTY,
          dateAtLastQualifyingEvent: '',
        },
      ],
      contract: {
        airframeMaintenanceReserveContract: {
          isMaintenanceReserveActive: false,
          isMaintenanceReserveFundFlushAtEvent: false,
          isEolActive: false,
          airframeMaintenanceRates: [
            {
              checkType,
              currentFund: 0,
              rateAmount: 0,
              maintenanceReserveCollectionType: MaintenanceReserveCollectionType.Cash,
              utilizationUnit: UtilizationUnits.Months,
              ratesYear: new Date().getFullYear(),
            },
          ],
        },
        escalations: {
          maintenanceReserveAgreedEscalationPercentage: 0,
        },
        minimumReDeliveryConditions: [],
        contractDeliverySnapshots: [
          {
            checkType,
            monthsSinceEventAtContractDelivery: 0,
          },
        ],
      },
    }

    const updatedData = [...newItems, newRow]
    registerFormField(`addRemoveAirframeChecks.addedItems.${updatedData.length - 1}`)
    setFormValue(`addRemoveAirframeChecks.addedItems.${updatedData.length - 1}`, newRow, {
      shouldDirty: true,
    })
    setFormValue('addRemoveAirframeChecks.addedItems', updatedData, { shouldDirty: true })
  }

  const onExistingRowRemove = (row: Row<AirframeCheckDisplayDTO>) => {
    const updatedUtilisationAtEvent = utilizationAtEvents.filter((_data, index) => index !== row.index)
    const updatedRates = rates.filter((_data, index) => index !== row.index)
    const updatedContractDeliverySnapshots = contractDeliverySnapshots.filter((_data, index) => index !== row.index)

    unRegisterFormField(`assetComponentUtilizationSnapshot.componentUtilizationAtEvents.${row.index}`)
    setFormValue('assetComponentUtilizationSnapshot.componentUtilizationAtEvents', updatedUtilisationAtEvent, { shouldDirty: true })

    unRegisterFormField(`airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates.${row.index}`)
    setFormValue('airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates', updatedRates, { shouldDirty: true })

    unRegisterFormField(`airframeContract.contractDeliverySnapshots.${row.index}`)
    setFormValue('airframeContract.contractDeliverySnapshots', updatedContractDeliverySnapshots, { shouldDirty: true })

    const removedIds = [...removedItemsIds, row.original.componentUtilizationAtEvents[0].checkId]
    registerFormField(`addRemoveAirframeChecks.removedItemIds.${updatedUtilisationAtEvent.length - 1}`)
    setFormValue('addRemoveAirframeChecks.removedItemIds', removedIds, { shouldDirty: true })
  }

  const onNewRowRemove = (row: Row<AirframeCheckDisplayDTO>) => {
    const updatedData = newItems.filter((_data, index) => index !== row.index)
    unRegisterFormField(`addRemoveAirframeChecks.addedItems.${row.index}`)
    setFormValue('addRemoveAirframeChecks.addedItems', updatedData, { shouldDirty: true })
  }

  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full gap-6">
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="MR Active"
          formControl={formControl}
          disabled={isArrayEmptyOrNull(airframe.airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates)}
          fieldName={`airframeContract.airframeMaintenanceReserveContract.isMaintenanceReserveActive`}
        />
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="EOL Applicable"
          formControl={formControl}
          disabled={isArrayEmptyOrNull(airframe.airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates)}
          fieldName={`airframeContract.airframeMaintenanceReserveContract.isEolActive`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Percentage}
          className="basis-1/6"
          label={`${dynamicMrEolLabel} Escalation`}
          formControl={formControl}
          fieldName={`airframeContract.escalations.maintenanceReserveAgreedEscalationPercentage`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <div className="mr-18 basis-3/6 " />
      </div>
      <div className="flex gap-x-6">
        <RedeliveryConditionsEditable
          label="Redelivery Conditions"
          fieldPath="airframeContract.minimumReDeliveryConditions"
          formControl={formControl}
          conditions={minimumRedeliveryConditions}
        />
        <div className="mr-12 basis-2/6" />
      </div>
      <div className="w-300">
        <AirframeChecksEditableTable
          existingItems={existingAirframeChecks}
          newItems={newAirframeChecks}
          checkTypesOptions={remainingOptions}
          onAddRow={onAddRow}
          onExistingRowRemove={onExistingRowRemove}
          onNewRowRemove={onNewRowRemove}
          dynamicMrEolLabel={dynamicMrEolLabel}
          mrActive={maintenanceReserve.isMaintenanceReserveActive}
          eolActive={maintenanceReserve.isEolActive}
          formControl={formControl}
          formState={formState}
          registerFormField={registerFormField}
          unRegisterFormField={unRegisterFormField}
          setFormValue={setFormValue}
        />
      </div>
    </div>
  )
}
