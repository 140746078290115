import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetStandaloneEnginesByAssetIdQueryResponse } from './types'
import { EnvironmentalImpactType } from '@flyward/platform'

const standaloneEnginesApiMocks = [
  http.get(APIRoutes.AssetsModule.StandAloneEngineController.GetStandAloneEngineDetails('*'), () => {
    return HttpResponse.json<IGetStandaloneEnginesByAssetIdQueryResponse>(
      {
        leaseStartDate: '2021-06-11',
        leaseEndDate: '2039-10-31',
        dateOfLastTechSpec: '2039-10-31',
        lessee: 'Air Aviation Network SAS',
        lesseeHabitualBase: 'Indonesia',
        portfolio: 'Tiger',
        region: 'EMEA',
        operatingEnvironment: EnvironmentalImpactType.Benign,
        averageMonthlyFlightHours: 0,
        averageMonthlyFlightCycles: 0,
        averageFhFcRatio: 0,
      },
      { status: 200 },
    )
  }),
]

export { standaloneEnginesApiMocks }
