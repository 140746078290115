import { type ComponentUtilizationSnapshot, type ComponentType, ComponentUtilizationSnapshotSchema } from '@flyward/platform/models'
import { ManufacturingDetailsDtoSchema, type ManufacturingDetailsDto } from './ManufacturingDetailsDto'
import { z } from 'zod'
import { validateRequiredString } from '@flyward/platform/helpers/inputValidators'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Abstract.ComponentDto
 */
export interface ComponentDto {
  assetId: string

  componentId: string
  componentSerialNumber: string
  componentType: ComponentType
  componentModel: string

  partNumber?: string

  manufacturingDetail?: ManufacturingDetailsDto

  assetComponentUtilizationSnapshot: ComponentUtilizationSnapshot

  assignedKBProgramId?: string
}

export const ComponentSchema = z.object({
  assetId: z.string(),
  componentId: z.string(),
  componentSerialNumber: validateRequiredString('Serial number'),
  componentType: z.any(),
  componentModel: z.any(),

  partNumber: z.string().optional().nullable(),

  manufacturingDetail: ManufacturingDetailsDtoSchema.optional().nullable(),

  assetComponentUtilizationSnapshot: ComponentUtilizationSnapshotSchema.optional().nullable(),

  assignedKBProgramId: z.string().optional().nullable(),
})
