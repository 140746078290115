/* eslint-disable @typescript-eslint/no-misused-promises */
import { ApuProgramDtoSchema, type ApuProgramDto } from '@flyward/knowledgeBase'
import { PageContainer } from '@flyward/main-app/layout'
import { AsyncErrorBoundary, CnForm, errorMessages, useHeaderContent, useNavigationState, useSpinnerState } from '@flyward/platform'
import {
  useGetAuxiliaryPowerUnitMaintenanceProgramDetailsQuery,
  useUpdateAuxiliaryPowerUnitMaintenanceProgramDetailsMutation,
} from '@flyward/platform/store'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { AuxiliaryPowerUnitMaintenanceProgramPageBody } from './AuxiliaryPowerUnitMaintenanceProgramPageBody'
import { AuxiliaryPowerUnitMaintenanceProgramPageHeader } from './AuxiliaryPowerUnitMaintenanceProgramPageHeader'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError, showSuccess } from '@flyward/platform/services'

export const AuxiliaryPowerUnitMaintenanceProgramPage = () => {
  const { id } = useParams()

  const submitRef = useRef<HTMLInputElement>(null)
  const [updateAuxiliaryPowerUnit] = useUpdateAuxiliaryPowerUnitMaintenanceProgramDetailsMutation()

  const { showSpinner, hideSpinner } = useSpinnerState()

  const { setTitle, setInputs, setButtons, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()

  const [isInEditMode, setIsInEditMode] = useState(false)

  const { data: auxiliaryPowerUnitMaintenanceProgram, isLoading } = useGetAuxiliaryPowerUnitMaintenanceProgramDetailsQuery(
    { programId: id! },
    {
      skip: isNil(id) || isEmpty(id),
    },
  )

  isLoading ? showSpinner() : hideSpinner()

  useEffect(() => {
    toggleVisibility()
    setTitle('APU Maintenance Program')
    setHasBackButton(true)

    return () => {
      setTitle('')
      setButtons([])
      setHasBackButton(false)
      setInputs([])
      toggleVisibility()
    }
  }, [setButtons, setTitle, setInputs, setHasBackButton, auxiliaryPowerUnitMaintenanceProgram?.kbReferenceYear, toggleVisibility])

  const form = useForm<ApuProgramDto>({
    defaultValues: auxiliaryPowerUnitMaintenanceProgram,
    values: auxiliaryPowerUnitMaintenanceProgram,
    resolver: zodResolver(ApuProgramDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleAuxiliaryPowerUnitUpdate,
    formState,
    getValues: getAuxiliaryPowerUnitFormValues,
    control: formControl,
    trigger: triggerValidations,
  } = form

  const auxiliaryPowerUnitFormValues: ApuProgramDto = getAuxiliaryPowerUnitFormValues()

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('KB Auxiliary Power Unit formState.errors', formState.errors)
  }

  useEffect(() => {
    triggerValidations()
  }, [isValid, triggerValidations])

  const onUpdateAuxiliaryPowerUnit: SubmitHandler<ApuProgramDto> = async (data) => {
    const result = await updateAuxiliaryPowerUnit({ programId: id!, apu: data })
    if (!isNil(result.error)) {
      showError(formatAxiosErrorMessage(result.error?.message, errorMessages.kb.update))
    } else {
      showSuccess(<span>Auxiliary Power Unit maintenance program updated successfully!</span>)
    }
  }

  const submitForm = () => {
    submitRef.current?.click()
  }

  return (
    <AsyncErrorBoundary>
      <CnForm {...form}>
        <form onSubmit={handleAuxiliaryPowerUnitUpdate(onUpdateAuxiliaryPowerUnit)}>
          <AuxiliaryPowerUnitMaintenanceProgramPageHeader
            isInEditMode={isInEditMode}
            setIsInEditMode={setIsInEditMode}
            isDirty={isDirty}
            isValid={isValid}
            submitForm={submitForm}
          />
          <PageContainer>
            <AuxiliaryPowerUnitMaintenanceProgramPageBody
              auxiliaryPowerUnitMaintenanceProgram={auxiliaryPowerUnitFormValues}
              isInEditMode={isInEditMode}
              formControl={formControl}
            />
          </PageContainer>
          <input type="submit" className="hidden" ref={submitRef} />
        </form>
      </CnForm>
    </AsyncErrorBoundary>
  )
}
