import { type StandAloneEngineDTO } from '@flyward/assets/models'
import {
  AsyncErrorBoundary,
  ComponentContainer,
  EditableDateCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  EditableSelectCellWithLabel,
  EnvironmentalImpactTypeOptions,
  InputType,
} from '@flyward/platform'
import { type Control, type FormState, type UseFormGetValues, type UseFormSetValue, type UseFormWatch } from 'react-hook-form'

interface IEditStandaloneEngineGeneralEditProps {
  formControl: Control<StandAloneEngineDTO, unknown>
  formState: FormState<StandAloneEngineDTO>
  watchFields: UseFormWatch<StandAloneEngineDTO>
  setFormValue: UseFormSetValue<StandAloneEngineDTO>
  getStandaloneEngineFormValues: UseFormGetValues<StandAloneEngineDTO>
}

export const StandaloneEngineEditableDetails = ({
  formControl,
  setFormValue,
  getStandaloneEngineFormValues,
}: IEditStandaloneEngineGeneralEditProps) => {
  const updateFhFcRatio = () => {
    const averageMonthlyFlightHours = getStandaloneEngineFormValues().averageMonthlyFlightHours
    const averageMonthlyFlightCycles = getStandaloneEngineFormValues().averageMonthlyFlightCycles

    const newFhFCRation = Number((averageMonthlyFlightHours / averageMonthlyFlightCycles).toFixed(2))
    setFormValue('averageFhFcRatio', newFhFCRation, { shouldDirty: true, shouldTouch: true })
  }

  return (
    <ComponentContainer className="p-6">
      <AsyncErrorBoundary>
        <div className="flex w-full flex-col">
          <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">General Information</p>
          <div className="flex flex-col gap-x-6 gap-y-2">
            <div className="flex gap-x-6">
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Operator" formControl={formControl} fieldName={`lessee`} />
              <EditableInputCellWithLabelWithLabel
                className="basis-1/6"
                label="Lessee Habitual Base"
                formControl={formControl}
                fieldName={`lesseeHabitualBase`}
              />
              <EditableDateCellWithLabelWithLabel
                className="basis-1/6"
                label="Lease Start"
                formControl={formControl}
                fieldName={`leaseStartDate`}
                setFormValue={setFormValue}
              />
              <EditableDateCellWithLabelWithLabel
                className="basis-1/6"
                label="Lease End"
                formControl={formControl}
                fieldName={`leaseEndDate`}
                setFormValue={setFormValue}
              />
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Region" formControl={formControl} fieldName={`region`} />
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Portfolio" formControl={formControl} fieldName={`portfolio`} />
            </div>
            <div className="flex w-full gap-x-6">
              <EditableDateCellWithLabelWithLabel
                className="basis-1/6"
                label="Tech Spec. Date"
                formControl={formControl}
                fieldName={`dateOfLastTechSpec`}
                setFormValue={setFormValue}
                allowClear={false}
              />
              <EditableSelectCellWithLabel
                className="basis-1/6"
                label="Operating Environment"
                options={EnvironmentalImpactTypeOptions}
                formControl={formControl}
                fieldName={`operatingEnvironment`}
              />
              <div className="basis-4/6" />
            </div>
            <div className="flex gap-x-6">
              <EditableInputCellWithLabelWithLabel
                inputType={InputType.NaturalNumber}
                className="basis-1/6"
                label="Average Monthly Flight Hours"
                formControl={formControl}
                fieldName={`averageMonthlyFlightHours`}
                afterChange={(_newValue) => {
                  updateFhFcRatio()
                }}
              />

              <EditableInputCellWithLabelWithLabel
                inputType={InputType.NaturalNumber}
                className="basis-1/6"
                label="Average Monthly Flight Cycles"
                formControl={formControl}
                fieldName={`averageMonthlyFlightCycles`}
                afterChange={(_newValue) => {
                  updateFhFcRatio()
                }}
              />

              <EditableInputCellWithLabelWithLabel
                isReadOnly={true}
                inputType={InputType.Decimal}
                className="basis-1/6"
                label="Average FH/FC Ratio"
                formControl={formControl}
                fieldName={`averageFhFcRatio`}
              />

              <div className="mr-9 basis-3/6"></div>
            </div>
          </div>
        </div>
      </AsyncErrorBoundary>
    </ComponentContainer>
  )
}
