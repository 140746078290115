import { validateRequiredNaturalNumber, validateRequiredDecimalNumber } from '@flyward/platform/helpers/inputValidators'
import { type CheckType, type UtilizationUnits } from '../../../../platform/models/enums'
import { z } from 'zod'

/**
 * Cloned from API: KnowledgeBase.Core.Domain.DTOs
 */
export interface CheckDto {
  id: string
  checkType: CheckType
  utilizationUnit: UtilizationUnits
  baseCost: number
  matureLimit: number
}

export const CheckDtoSchema = z.object({
  id: z.any().optional().nullable(),
  checkType: validateRequiredNaturalNumber('Check Type'),
  utilizationUnit: z.any().optional().nullable(),
  baseCost: validateRequiredDecimalNumber('Cost'),
  matureLimit: validateRequiredNaturalNumber('Limit'),
})
