import { ActionDialog, Button, ButtonVariant, DialogSize } from '@flyward/platform'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { type VerifyAssetResultDto } from '../../../../models'
import { AssetAlignmentWizard } from './Align/'
import { Dialog } from '@mui/material'

interface IAssetVerificationModalProps {
  assetId: string
  alignmentData: VerifyAssetResultDto
  selectedAirframeProgramId?: string
  selectedEngineProgramId?: string
}

const AssetVerificationModal = ({ assetId, alignmentData, selectedAirframeProgramId, selectedEngineProgramId }: IAssetVerificationModalProps) => {
  const [isAssetVerificationModalOpen, setIsAssetVerificationModalOpen] = useState(false)

  const noProgramAssigned = isEmpty(selectedAirframeProgramId) && isEmpty(selectedEngineProgramId)

  return (
    <>
      <Button
        variant={ButtonVariant.Secondary}
        labelClassName="p-2"
        label={'Align'}
        onClick={() => {
          setIsAssetVerificationModalOpen(true)
        }}
      />
      {noProgramAssigned ? (
        <ActionDialog
          size={DialogSize.Small}
          confirmBtnLabel="OK"
          isValid={true}
          dialogHeader={<p>Align Asset</p>}
          hideCancelBtn
          onConfirm={async () => {}}
          onCancel={() => {}}
          onSuccess={() => {}}
          isOpen={isAssetVerificationModalOpen}
          setIsOpen={setIsAssetVerificationModalOpen}
          dialogContent={<p>No maintenance program assigned</p>}
        />
      ) : (
        <Dialog fullScreen open={isAssetVerificationModalOpen}>
          <div className="w-full p-4">
            <AssetAlignmentWizard
              assetId={assetId}
              alignmentData={alignmentData}
              selectedAirframeProgramId={selectedAirframeProgramId}
              selectedEngineProgramId={selectedEngineProgramId}
              setIsAssetVerificationModalOpen={setIsAssetVerificationModalOpen}
            />
          </div>
        </Dialog>
      )}
    </>
  )
}

export { AssetVerificationModal }
