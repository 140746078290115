import { useReactTable, getCoreRowModel } from '@tanstack/react-table'
import { KbAirframeChecksHeader } from './KbAirframeChecksHeader'
import { KbAirframeCheckRows } from './KbAirframeCheckRows'
import { type VerifyAirframeCheckResultDto } from '../../../../../../../models/aircraftComponents/airframe/verify/VerifyAirframeCheckResultDto'
import { readOnlyColumns } from './columns'
import { type RowSizeMap } from '@flyward/platform/components'
import { useState } from 'react'

interface IAssetAlignmentWizardProps {
  checksResults: VerifyAirframeCheckResultDto[]
}

export const KbAirframeChecksTable = ({ checksResults }: IAssetAlignmentWizardProps) => {
  const [columnSizes, setColumnSizes] = useState<RowSizeMap>({
    kbCheck_checkType: { defaultSize: 80, minSize: 50, maxSize: 160, currentSize: 80 },
    kbCheck_utilizationUnit: { defaultSize: 90, minSize: 50, maxSize: 170, currentSize: 90 },
    kbCheck_limitAmount: { defaultSize: 100, minSize: 50, maxSize: 180, currentSize: 100 },
    kbCheck_baseCost: { defaultSize: 110, minSize: 50, maxSize: 200, currentSize: 110 },
  })

  const table = useReactTable<VerifyAirframeCheckResultDto>({
    data: checksResults,
    columns: readOnlyColumns(columnSizes),
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
  })

  return (
    <table className="max-w-fit basis-4/12 table-auto border-collapse border border-black-20">
      <KbAirframeChecksHeader headerGroups={table.getHeaderGroups()} columnSizes={columnSizes} setColumnSizes={setColumnSizes} />
      <KbAirframeCheckRows rowModel={table.getRowModel()} />
    </table>
  )
}
