import { validateNullablePositiveDecimalNumber } from '@flyward/platform/helpers/inputValidators'
import { z } from 'zod'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Abstract.EscalationDto
 */
export interface EscalationDto {
  maintenanceReserveAgreedEscalationPercentage: number
}

export const EscalationDtoSchema = z.object({
  maintenanceReserveAgreedEscalationPercentage: validateNullablePositiveDecimalNumber,
})
