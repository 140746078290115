import { type AircraftEngineDto, type AirframeDto, type ApuDto, type LandingGearDto } from '../../../../models'
import { AirframeDetails } from './Airframe'
import { AuxiliaryPowerUnitDetails } from './AuxiliaryPowerUnit'
import { EngineDetails } from '../../EngineDetails'
import { LandingGearDetails } from './LandingGear'
import { AsyncErrorBoundary } from '@flyward/platform'
import { type AircraftDetailsDto } from '@flyward/assets/models/DTOs/aircraftDetailsDto'
import { AircraftDetails } from '../AircraftSummary'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { isNil } from 'lodash'

interface IAircraftTechSpecProps {
  assetId: string
  aircraft: AircraftDetailsDto | null | undefined
  airframes: AirframeDto[] | undefined
  engines: AircraftEngineDto[] | undefined
  auxiliaryPowerUnits: ApuDto[] | undefined
  landingGears: LandingGearDto[] | undefined
}

export const AircraftTechSpec = ({ assetId, aircraft, airframes, engines, auxiliaryPowerUnits, landingGears }: IAircraftTechSpecProps) => {
  return (
    <div className="flex flex-col gap-y-4">
      <AsyncErrorBoundary>
        {!isNil(aircraft) && <AircraftDetails aircraft={aircraft} assetId={assetId} />}
        {!isNil(aircraft) &&
          !isArrayEmptyOrNull(engines) &&
          airframes?.map((airframe) => <AirframeDetails key={airframe.componentId} aircraft={aircraft} airframe={airframe} />)}
        {!isArrayEmptyOrNull(engines) && (engines ?? [])?.map((engine) => <EngineDetails key={engine.componentId} engine={engine} />)}
        {!isArrayEmptyOrNull(landingGears) &&
          (landingGears ?? [])?.map((landingGear) => <LandingGearDetails key={landingGear.componentId} landingGear={landingGear} />)}
        {!isArrayEmptyOrNull(auxiliaryPowerUnits) &&
          (auxiliaryPowerUnits ?? [])?.map((auxiliaryPowerUnit) => (
            <AuxiliaryPowerUnitDetails key={auxiliaryPowerUnit.componentId} auxiliaryPowerUnit={auxiliaryPowerUnit} />
          ))}
      </AsyncErrorBoundary>
    </div>
  )
}
