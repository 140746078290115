import { ComponentUtilizationSnapshotSchema, type UtilizationUnits } from '@flyward/platform'
import { type ComponentDto } from '../../ComponentDto'
import { z } from 'zod'
import { ManufacturingDetailsDtoSchema } from '../../ManufacturingDetailsDto'
import { validateRequiredString, validateNullableNaturalNumber } from '@flyward/platform/helpers/inputValidators'

/**
 * Cloned from from API: Assets.Core.Domain.DTOs.Components.Engine.EngineLifeLimitedPartDto
 */
export interface EngineLifeLimitedPartDto extends ComponentDto {
  componentModule: string
  technicalLimitUnit: UtilizationUnits
  technicalLimitAmount: number
  cyclesSinceNewAtContractDelivery?: number
}

export const EngineLifeLimitedPartDtoSchema = z.object({
  // copied from ComponentSchema
  componentId: z.string().optional().nullable(),
  componentSerialNumber: z.string().optional().nullable(),
  componentType: z.any(),
  componentModel: validateRequiredString('Description'),
  partNumber: z.string().optional().nullable(),
  manufacturingDetail: ManufacturingDetailsDtoSchema.optional().nullable(),
  assetComponentUtilizationSnapshot: ComponentUtilizationSnapshotSchema.optional().nullable(),
  assignedKBProgramId: z.string().optional().nullable(),

  componentModule: z.any().nullable(),
  technicalLimitUnit: z.any().nullable(),
  technicalLimitAmount: validateNullableNaturalNumber,
  cyclesSinceNewAtContractDelivery: validateNullableNaturalNumber,
})
