/**
 * Cloned from from API: Assets.Core.Domain.Entities.Asset.ManufacturingDetailsDto
 */
import { validateRequiredDate } from '@flyward/platform/helpers/inputValidators'
import { z } from 'zod'

export interface ManufacturingDetailsDto {
  manufacturer?: string
  dom: string
}

export const ManufacturingDetailsDtoSchema = z.object({
  manufacturer: z.any().optional().nullable(),
  dom: validateRequiredDate('DOM'),
})
