import { type VerifyAssetResultDto } from '../../../../../models'
import { OutletTabs } from '@flyward/platform/components'
import { type ITabs } from '@flyward/platform/components/Tabs/TabsBase'
import { isEmpty, isNil } from 'lodash'
import { AirframeAlignment } from './AirframeAlignment'
import { EngineAlignment } from './EngineAlignment'
import { AssetVerificationBadge } from '../AssetVerificationBadge'

interface IAssetAlignmentWizardProps {
  assetId: string
  alignmentData: VerifyAssetResultDto
  setIsAssetVerificationModalOpen: (isOpen: boolean) => void
  selectedAirframeProgramId?: string
  selectedEngineProgramId?: string
}

const AssetAlignmentWizard = ({
  assetId,
  alignmentData,
  setIsAssetVerificationModalOpen,
  selectedAirframeProgramId,
  selectedEngineProgramId,
}: IAssetAlignmentWizardProps) => {
  let tabs: ITabs = {}

  if (!isEmpty(selectedAirframeProgramId) && !isNil(alignmentData.airframeResult)) {
    tabs = {
      ...tabs,
      Airframe: {
        content: (
          <AirframeAlignment
            assetId={assetId}
            airframeResult={alignmentData.airframeResult}
            setIsAssetVerificationModalOpen={setIsAssetVerificationModalOpen}
          />
        ),
        tabContent: (
          <div className="flex items-center gap-x-2">
            <AssetVerificationBadge isSuccess={alignmentData?.airframeResult?.isSuccess ?? false} />
            Airframe
          </div>
        ),
      },
    }
  }

  if (!isEmpty(selectedEngineProgramId)) {
    alignmentData.engineResults.forEach(
      (engineResult) =>
        (tabs = {
          ...tabs,
          [`Engine ${engineResult.engineSerialNumber}`]: {
            content: (
              <EngineAlignment assetId={assetId} engineResult={engineResult} setIsAssetVerificationModalOpen={setIsAssetVerificationModalOpen} />
            ),
            tabContent: (
              <div className="flex items-center gap-x-2">
                <AssetVerificationBadge isSuccess={engineResult?.isSuccess ?? false} />
                Engine {engineResult.engineSerialNumber}
              </div>
            ),
          },
        }),
    )
  }

  return (
    <div className="w-[calc(100vw-3rem) h-full max-h-screen">
      <OutletTabs tabs={tabs} />
    </div>
  )
}

export { AssetAlignmentWizard }
