import { type RowSizeMap } from '@flyward/platform/components'
import { type DraggableLlp } from '../../../../../../../models/aircraftComponents/aircraftEngine'
import { flexRender, type HeaderGroup } from '@tanstack/react-table'
import { isNil } from 'lodash'

interface IAssetLlpsHeaderProps {
  headerGroups: Array<HeaderGroup<DraggableLlp>>
  columnSizes: RowSizeMap
  setColumnSizes: (columnSizes: RowSizeMap) => void
}

const AssetLlpsHeader = ({ headerGroups, columnSizes, setColumnSizes }: IAssetLlpsHeaderProps) => {
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const accessorKey = header.column.id

            const currentSize = header.getSize()

            if (!isNil(columnSizes[accessorKey])) {
              columnSizes[accessorKey].currentSize = currentSize
              setColumnSizes(columnSizes)
            }

            return (
              <th
                key={header.id}
                colSpan={header.colSpan}
                style={{
                  width: `${columnSizes[accessorKey]?.currentSize}px`,
                  maxWidth: `${columnSizes[accessorKey]?.currentSize}px`,
                }}
                className="relative truncate border border-black-20 bg-primary-dark-1"
              >
                {header.isPlaceholder ? null : <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>}

                {/* Resize Handle */}
                {header.column.getCanResize() && (
                  <div
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={`absolute right-0 top-0 h-full w-1 cursor-col-resize ${header.column.getIsResizing() ? '!bg-blue-300' : ''}`}
                  />
                )}
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
  )
}

export { AssetLlpsHeader }
