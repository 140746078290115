import { EngineTechSpec } from '@flyward/assets'
import { AssetVerification } from '@flyward/assets/components/AircraftComponents/AircraftDetails/Verify'
import { OutletTabs, useHeaderContent, useNavigationState } from '@flyward/platform'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError } from '@flyward/platform/services'
import { useGetAllEnginesByAssetIdQuery, useGetAllStandaloneEnginesByAssetIdQuery } from '@flyward/platform/store'
import { isNil } from 'lodash'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../../layout'
import { StandaloneEngineMaintenanceProgramSelection } from './StandaloneEngineMaintenanceProgramSelection'

export const StandaloneEnginePageBody = () => {
  const currentTab = useRef<string>('Tech Spec')

  const onTabChanged = (activeTab: string) => {
    currentTab.current = activeTab
  }

  const { id } = useParams()
  const { setTitle, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()

  const assetId: string = id ?? ''

  const {
    data: engines = [],
    isFetching: isFetchingEngines,
    isLoading: isLoadingEngines,
  } = useGetAllEnginesByAssetIdQuery({ assetId, includeLLPs: true })
  const { data: standaloneEngines, error: getStandaloneEngineError } = useGetAllStandaloneEnginesByAssetIdQuery({ assetId })

  useEffect(() => {
    if (!isNil(getStandaloneEngineError)) {
      showError(formatAxiosErrorMessage(getStandaloneEngineError?.message))
    }
  }, [getStandaloneEngineError])

  const isLoading = isFetchingEngines || isLoadingEngines

  useEffect(() => {
    toggleVisibility()
    setTitle('View Engine')
    setHasBackButton(true)

    return () => {
      toggleVisibility()
    }
  }, [setHasBackButton, setTitle, toggleVisibility, assetId])

  const engineAssignedKBProgramId = engines?.[0]?.assignedKBProgramId ?? ''

  const tabs = {
    'Tech Spec': {
      content: <EngineTechSpec assetId={assetId} engine={engines?.[0]} standAloneEngine={standaloneEngines} />,
    },
    'Maintenance Program': {
      content: <StandaloneEngineMaintenanceProgramSelection assetId={assetId} engines={engines} />,
    },
  }

  return (
    <PageContainer>
      <div className="absolute right-46 top-4">
        <AssetVerification isLoading={isLoading} assetId={assetId} selectedEngineProgramId={engineAssignedKBProgramId} />
      </div>
      <OutletTabs tabs={tabs} activeTab={currentTab.current} onTabChanged={onTabChanged} />
    </PageContainer>
  )
}
