import { type LoggedInUserDto } from '@flyward/platform'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { Role } from '@flyward/platform/models/enums/Role'
import { isNil } from 'lodash'

const availableDataId = new Map<Role, string[]>([
  [
    Role.Admin,
    [
      'user-management-btn',
      'delete-any-report',
      'delete-any-asset',
      'edit-asset',
      'asset-import',
      'knowledge-program-import',
      'delete-any-maintenance-program',
      'change-state-any-maintenance-program',
      'align-asset',
    ],
  ],
  [Role.NormalUser, ['delete-own-report', 'read-only-asset-view']],
  [
    Role.SuperUser,
    ['delete-any-report', 'edit-asset', 'delete-any-asset', 'delete-any-maintenance-program', 'change-state-any-maintenance-program', 'align-asset'],
  ],
  [Role.None, []],
])

const isElementAvailable = (loggedUser: LoggedInUserDto | null, dataId: string): boolean => {
  if (isNil(loggedUser)) {
    return false
  }

  const allAvailableData = availableDataId.get(loggedUser.role)
  if (isArrayEmptyOrNull(allAvailableData)) {
    return false
  }

  for (const route of allAvailableData!) {
    if (route === dataId) {
      return true
    }
  }

  return false
}

export { isElementAvailable }
