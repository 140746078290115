import { createTheme } from '@mui/material'

export const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#00838f',
    },
    secondary: {
      main: '#292891',
    },
    error: {
      main: '#b03ac2;',
    },
    text: {
      primary: '#070d0e',
      secondary: '#404344',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFeatureSettings: "'liga' 1, 'calt' 1",
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      },
      styleOverrides: {
        root: {
          border: '1px solid gray',
          borderRadius: '4px',
          backgroundColor: '#f5f5f5',
        },
      },
    },
  },
})
