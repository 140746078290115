export enum AssetType {
  Aircraft = 1,
  StandaloneEngine = 2,
}

export const getAssetTypeDisplayName = (assetType: AssetType): string => {
  switch (assetType) {
    case AssetType.Aircraft:
      return 'Aircraft'
    case AssetType.StandaloneEngine:
      return 'Engine'
    default:
      return 'Asset'
  }
}
