/* eslint-disable @typescript-eslint/no-misused-promises */
import { AirframeProgramDtoSchema, type AirframeProgramDto } from '@flyward/knowledgeBase/models'
import { useGetAirframeMaintenanceProgramDetailsQuery, useUpdateAirframeMaintenanceProgramDetailsMutation } from '@flyward/knowledgeBase/store'
import { PageContainer } from '@flyward/main-app/layout'
import { AsyncErrorBoundary, CnForm, errorMessages, useHeaderContent, useNavigationState, useSpinnerState } from '@flyward/platform'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { AirframeMaintenanceProgramPageBody } from './AirframeMaintenanceProgramPageBody'
import { AirframeMaintenanceProgramPageHeader } from './AirframeMaintenanceProgramPageHeader'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError, showSuccess } from '@flyward/platform/services'

export const AirframeMaintenanceProgramPage = () => {
  const { id } = useParams()
  const submitRef = useRef<HTMLInputElement>(null)
  const [updateAirframe] = useUpdateAirframeMaintenanceProgramDetailsMutation()

  const { showSpinner, hideSpinner } = useSpinnerState()

  const { setTitle, setInputs, setButtons, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()

  const [isInEditMode, setIsInEditMode] = useState(false)

  const { data: airframeMaintenanceProgram, isLoading } = useGetAirframeMaintenanceProgramDetailsQuery(
    { programId: id! },
    {
      skip: isNil(id) || isEmpty(id),
    },
  )

  isLoading ? showSpinner() : hideSpinner()

  useEffect(() => {
    toggleVisibility()
    setTitle('Airframe Maintenance Program')
    setHasBackButton(true)

    return () => {
      setTitle('')
      setButtons([])
      setHasBackButton(false)
      setInputs([])
      toggleVisibility()
    }
  }, [setButtons, setHasBackButton, setInputs, setTitle, toggleVisibility])

  const form = useForm<AirframeProgramDto>({
    defaultValues: airframeMaintenanceProgram,
    values: airframeMaintenanceProgram,
    resolver: zodResolver(AirframeProgramDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleAirframeUpdate,
    formState,
    getValues: getAirframeFormValues,
    control: formControl,
    trigger: triggerValidations,
    reset,
    setValue,
    register,
    unregister,
  } = form

  const airframeFormValues: AirframeProgramDto = getAirframeFormValues()

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('KB Airframe formState.errors', formState.errors)
  }

  const onUpdateAirframe: SubmitHandler<AirframeProgramDto> = async (data) => {
    const result = await updateAirframe({ programId: id!, airframe: data })
    if (!isNil(result.error)) {
      reset()
      showError(formatAxiosErrorMessage(result.error?.message, errorMessages.kb.update))
    } else {
      reset()
      showSuccess(<span>Airframe maintenance program updated successfully</span>)
    }
  }

  useEffect(() => {
    triggerValidations()
  }, [isValid, triggerValidations])

  const submitForm = () => {
    submitRef.current?.click()
  }

  return (
    <AsyncErrorBoundary>
      <CnForm {...form}>
        <form onSubmit={handleAirframeUpdate(onUpdateAirframe)}>
          <AirframeMaintenanceProgramPageHeader
            isInEditMode={isInEditMode}
            setIsInEditMode={setIsInEditMode}
            isDirty={isDirty}
            isValid={isValid}
            submitForm={submitForm}
          />
          <PageContainer>
            <AirframeMaintenanceProgramPageBody
              airframeMaintenanceProgram={airframeFormValues}
              isInEditMode={isInEditMode}
              formControl={formControl}
              setFormValue={setValue}
              registerFormField={register}
              unRegisterFormField={unregister}
            />
          </PageContainer>
          <input type="submit" className="hidden" ref={submitRef} />
        </form>
      </CnForm>
    </AsyncErrorBoundary>
  )
}
