import { validateNullableNaturalNumber } from '@flyward/platform/helpers/inputValidators'
import { z } from 'zod'

export interface LessorContributionDto {
  isActive: boolean
  isUnlimited: boolean
  amount: number
}

export const LessorContributionDtoSchema = z.object({
  isActive: z.any().optional().nullable(),
  isUnlimited: z.any().optional().nullable(),
  amount: validateNullableNaturalNumber,
})
