import { type AddRemoveItems, AddRemoveItemsSchema } from '@flyward/platform'
import { z } from 'zod'
import { AirframeCheckDtoSchema, type AirframeCheckDto } from './AirframeCheckDto'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Airframe.UpdateAirframeChecksInput
 */
export interface UpdateAirframeChecksInput {
  airframeComponentId: string
  existingChecks: AirframeCheckDto[]
  addRemoveChecks?: AddRemoveItems<AirframeCheckDto>
}

export const UpdateAirframeChecksInputSchema = z.object({
  airframeComponentId: z.string(),
  existingChecks: z.array(AirframeCheckDtoSchema.nullable().optional()).nullable(),
  addRemoveChecks: AddRemoveItemsSchema(AirframeCheckDtoSchema.nullable().optional()).nullable().optional(),
})
