import { errorMessages } from '../../constants/errorMessages'

interface IValidationErrorDisplay {
  errors: Record<string, string[]>
}

const ValidationErrorDisplay = ({ errors }: IValidationErrorDisplay) => {
  return (
    <div>
      <p>Validation errors:</p>
      <ul className="list-disc">
        {Object.entries(errors).map(([key, value]) => {
          return (
            <li key={key}>
              <strong>{key}</strong>
              <ul className="list-disc">
                {value.map((error) => {
                  return (
                    <li className="pl-2" key={error}>
                      {error}
                    </li>
                  )
                })}
              </ul>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export interface IGenericErrorDisplay {
  errorCode: number
  displayErrorMessage?: React.ReactNode
}

const GenericErrorDisplay = ({ errorCode, displayErrorMessage = errorMessages.default }: IGenericErrorDisplay) => {
  let errorPrefix = ''

  switch (errorCode) {
    case 404:
      errorPrefix = 'Could not find resource'
      break
    case 422:
      errorPrefix = 'Error'
      break
    case 500:
      errorPrefix = 'Internal server error'
      break
    default:
      errorPrefix = 'Undefined error'
      break
  }

  return (
    <div>
      <p>{errorPrefix}:</p>
      {displayErrorMessage}
    </div>
  )
}

export { ValidationErrorDisplay, type IValidationErrorDisplay, GenericErrorDisplay }
