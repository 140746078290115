export enum AssetImportType {
  ExcelTemplate = 1,
  PdfFile = 2,
}

export const getAssetImportTypeDisplayName = (assetImportType: AssetImportType): string => {
  switch (assetImportType) {
    case AssetImportType.ExcelTemplate:
      return 'Excel Template'
    case AssetImportType.PdfFile:
      return 'Tech Spec'
    default:
      return 'File'
  }
}
