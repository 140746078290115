import { NumberDisplay, NumberDisplayType, type RowSizeMap, SimpleHeader } from '@flyward/platform/components'
import { type ColumnDef } from '@tanstack/react-table'
import { type VerifyAirframeCheckResultDto } from '../../../../../../../models/aircraftComponents/airframe/verify/VerifyAirframeCheckResultDto'
import { CheckTypeTableDisplay, UtilizationUnitsDisplay } from '@flyward/platform/models/enums'

const readOnlyColumns = (columnSizes: RowSizeMap): Array<ColumnDef<VerifyAirframeCheckResultDto>> => [
  {
    accessorKey: 'kbCheck.checkType',
    header: () => (
      <div
        className="flex items-center justify-center"
        style={{
          width: columnSizes.kbCheck_checkType?.currentSize ?? 0,
          maxWidth: columnSizes.kbCheck_checkType?.currentSize ?? 0,
        }}
      >
        <SimpleHeader title="Event Type" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
      </div>
    ),
    size: columnSizes.kbCheck_checkType?.defaultSize ?? 0,
    minSize: columnSizes.kbCheck_checkType?.minSize ?? 0,
    maxSize: columnSizes.kbCheck_checkType?.maxSize ?? 0,
    cell: ({ row }) => (
      <div
        className="w-full min-w-full pt-1 text-xs"
        style={{
          width: columnSizes.kbCheck_baseCost?.currentSize ?? 0,
          maxWidth: columnSizes.kbCheck_baseCost?.currentSize ?? 0,
        }}
      >
        {row.original.kbCheck?.checkType != null && CheckTypeTableDisplay(row.original.kbCheck.checkType)}
      </div>
    ),
  },
  {
    accessorKey: 'kbCheck.utilizationUnit',
    header: () => (
      <div
        className="items-center justify-center"
        style={{
          width: columnSizes.kbCheck_utilizationUnit?.currentSize ?? 0,
          maxWidth: columnSizes.kbCheck_utilizationUnit?.currentSize ?? 0,
        }}
      >
        <SimpleHeader title="Unit" className="text-semibold m-0 w-full truncate p-1  text-center text-xs text-black-0" />
      </div>
    ),
    size: columnSizes.kbCheck_utilizationUnit?.defaultSize ?? 0,
    minSize: columnSizes.kbCheck_utilizationUnit?.minSize ?? 0,
    maxSize: columnSizes.kbCheck_utilizationUnit?.maxSize ?? 0,
    cell: ({ row }) => (
      <div
        className="w-full min-w-full pt-1 text-xs"
        style={{
          width: columnSizes.kbCheck_baseCost?.currentSize ?? 0,
          maxWidth: columnSizes.kbCheck_baseCost?.currentSize ?? 0,
        }}
      >
        {row.original.kbCheck?.utilizationUnit != null && UtilizationUnitsDisplay(row.original.kbCheck.utilizationUnit)}
      </div>
    ),
  },
  {
    accessorKey: 'kbCheck.limitAmount',
    header: () => (
      <div
        className="flex items-center justify-center"
        style={{
          width: columnSizes.kbCheck_limitAmount?.currentSize ?? 0,
          maxWidth: columnSizes.kbCheck_limitAmount?.currentSize ?? 0,
        }}
      >
        <SimpleHeader title="Limit" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
      </div>
    ),
    size: columnSizes.kbCheck_limitAmount?.defaultSize ?? 0,
    minSize: columnSizes.kbCheck_limitAmount?.minSize ?? 0,
    maxSize: columnSizes.kbCheck_limitAmount?.maxSize ?? 0,
    cell: ({ row }) => (
      <div
        className="w-full min-w-full pt-1 text-xs"
        style={{
          width: columnSizes.kbCheck_baseCost?.currentSize ?? 0,
          maxWidth: columnSizes.kbCheck_baseCost?.currentSize ?? 0,
        }}
      >
        <NumberDisplay displayType={NumberDisplayType.Decimal} currencyDecimals={2} value={row.original.kbCheck?.limitAmount} />
      </div>
    ),
  },
  {
    accessorKey: 'kbCheck.baseCost',
    header: () => (
      <div
        className="flex items-center justify-center"
        style={{
          width: columnSizes.kbCheck_baseCost?.currentSize ?? 0,
          maxWidth: columnSizes.kbCheck_baseCost?.currentSize ?? 0,
        }}
      >
        <SimpleHeader title="Cost" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
      </div>
    ),
    size: columnSizes.kbCheck_baseCost?.defaultSize ?? 0,
    minSize: columnSizes.kbCheck_baseCost?.minSize ?? 0,
    maxSize: columnSizes.kbCheck_baseCost?.maxSize ?? 0,
    cell: ({ row }) => (
      <div
        className="w-full min-w-full pt-1 text-xs"
        style={{
          width: columnSizes.kbCheck_baseCost?.currentSize ?? 0,
          maxWidth: columnSizes.kbCheck_baseCost?.currentSize ?? 0,
        }}
      >
        <NumberDisplay displayType={NumberDisplayType.Currency} currencyDecimals={2} value={row.original.kbCheck?.baseCost} />
      </div>
    ),
  },
]

export { readOnlyColumns }
