import { isNil } from 'lodash'
import { useState } from 'react'
import { cn } from '../_shadcn/utils/utils'
import { ActionDialog } from '../ActionDialog/ActionDialog'
import { Button, ButtonVariant } from '../Button/Button'
import { type IconVariant } from '../Icon/IconVariant'
import { showError, showSuccess } from '@flyward/platform/services'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'

interface IButtonWithDialog {
  id: string
  name: string
  onConfirm: (assetId: string) => Promise<string | undefined>
  type: string
  dataPermissionId: string
  actionLabel: string
  icon: IconVariant
  isExpanded?: boolean
}

export const ButtonWithDialog = ({ id, name, onConfirm, type, dataPermissionId, actionLabel, icon, isExpanded = false }: IButtonWithDialog) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  return (
    <>
      <Button
        variant={ButtonVariant.Ghost}
        leftIcon={icon}
        className={cn(`-mr-52 justify-end ${isExpanded && 'text-black-0'}`)}
        onClick={() => {
          setIsConfirmModalOpen(true)
        }}
      />

      <ActionDialog
        data-permission-element-id={dataPermissionId}
        confirmBtnLabel={actionLabel}
        dialogHeader={`${actionLabel} ${type}`}
        key={`action-${type}-${id}`}
        onConfirm={async () => {
          const resultWithError = await onConfirm(id)
          if (isNil(resultWithError)) {
            showSuccess(`${type} ${actionLabel.toLocaleLowerCase()} was successful!`)
          } else {
            showError(formatAxiosErrorMessage(resultWithError))
          }
        }}
        onCancel={() => {
          setIsConfirmModalOpen(false)
        }}
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        dialogContent={
          <p>
            Are you sure you want to {actionLabel.toLocaleLowerCase()} the {type} <strong>{name}</strong> ?
          </p>
        }
        isValid
      />
    </>
  )
}
