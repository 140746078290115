import { type AlignmentStatus } from '@flyward/platform/models/enums'
import { AirframeCheckDtoSchema, type AirframeCheckDto } from './AirframeCheckDto'
import { z } from 'zod'

export interface DraggableAirframeCheck {
  airframeCheck: AirframeCheckDto
  alignmentStatus: AlignmentStatus
}

export interface DraggableAirframeChecksStack {
  airframeChecks: DraggableAirframeCheck[]
  removedItemIds: string[]
}

const DraggableAirframeCheckSchema = z.object({
  airframeCheck: AirframeCheckDtoSchema.optional().nullable(),
  alignmentStatus: z.any().optional().nullable(),
})

export const DraggableAirframeChecksStackSchema = z.object({
  airframeChecks: z.array(DraggableAirframeCheckSchema),
  removedItemIds: z.array(z.any()).optional().nullable(),
})
