import { type CloseNotification, type ShowNotification } from '@toolpad/core/useNotifications'
import { isNil } from 'lodash'

export interface UseNotifications {
  show: ShowNotification
  close: CloseNotification
}

let notificationsInstance: UseNotifications | null = null

export const setNotificationsInstance = (instance: UseNotifications) => {
  notificationsInstance = instance
}

export const showSuccess = (message: React.ReactNode) => {
  if (isNil(notificationsInstance)) {
    console.warn('Notifications instance is not initialized.')
  } else {
    notificationsInstance.show(message, {
      severity: 'success',
      autoHideDuration: 3000,
    })
  }
}

export const showError = (message: React.ReactNode) => {
  if (isNil(notificationsInstance)) {
    console.warn('Notifications instance is not initialized.')
  } else {
    notificationsInstance.show(message, {
      severity: 'error',
    })
  }
}
