/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { AssetType, ButtonVariant, FlyToType, IconVariant, Size, useHeaderContent, type IButtonProps } from '@flyward/platform'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectUnsortedReportAssets } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { FlyForwardSave } from './FlyForwardSave'
import { useComputeSchedule } from './useComputeSchedule'
import { setSuccessfulReportId } from '@flyward/platform/store/slices'
import { isValidEndDate } from '@flyward/forecasts/helpers/flyForwardHelpers'
import { isWithinFiftyYears } from '@flyward/platform/helpers/dateHelpers'

const FlyForwardPageHeader = () => {
  const reportAssets = useAppSelector(selectUnsortedReportAssets)

  const { setTitle, setButtons } = useHeaderContent()

  const dispatch = useAppDispatch()

  const [canFlyForward, setCanFlyForward] = useState<boolean>(false)

  const { computeSchedule } = useComputeSchedule()

  useEffect(() => {
    const flyButtonProps: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Fly',
        leftIcon: IconVariant.FlightTakeOff,
        disabled: !canFlyForward,
        onClick: () => {
          dispatch(setSuccessfulReportId({ reportId: '' }))

          computeSchedule(reportAssets).then((reportId) => {
            if (!isNil(reportId)) {
              dispatch(setSuccessfulReportId({ reportId }))
            }
          })
        },
      },
    ]

    setTitle('Fly Forward')
    setButtons(flyButtonProps)

    return () => {
      setTitle('')
      setButtons([])
    }
  }, [setButtons, setTitle, computeSchedule, reportAssets, canFlyForward, dispatch])

  useEffect(() => {
    setCanFlyForward(false)

    if (reportAssets.length === 0) {
      setCanFlyForward(false)
      return
    }

    const canFlyForward = reportAssets.every((reportAsset) => {
      const techSpecDate: string = reportAsset.flyForwardParameters?.commonAssetDetails?.assetDetailsSnapshot.dateOfLastTechSpec ?? ''

      const isEndDateValid =
        (!isEmpty(techSpecDate) && reportAsset.flyForwardParameters.flyToType === FlyToType.Redelivery) ||
        (isValidEndDate(reportAsset.flyForwardParameters.endDate, techSpecDate) && isWithinFiftyYears(reportAsset.flyForwardParameters.endDate))

      const isAvgFlightHoursValid = reportAsset.flyForwardParameters.averageMonthlyFlightHours > 0
      const isAvgFlightCyclesValid = reportAsset.flyForwardParameters.averageMonthlyFlightCycles > 0
      const isAvgApuHoursValid =
        reportAsset.flyForwardParameters.assetType === AssetType.StandaloneEngine || reportAsset.flyForwardParameters.averageMonthlyAPUHours > 0

      return isEndDateValid && isAvgFlightHoursValid && isAvgFlightCyclesValid && isAvgApuHoursValid
    })

    setCanFlyForward(canFlyForward)
  }, [reportAssets])

  return <FlyForwardSave />
}

export { FlyForwardPageHeader }
