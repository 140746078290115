import { flexRender, type Row } from '@tanstack/react-table'
import { type VerifyLlpResultDto } from '../../../../../../../models/aircraftComponents/aircraftEngine/verify/VerifyLlpResultDto'

interface IKbLLpRowProps {
  llpResult: Row<VerifyLlpResultDto>
}

const KbLLpRow = ({ llpResult }: IKbLLpRowProps) => {
  return (
    <tr className="mb-2 box-border h-6 min-h-6 border border-black-20 bg-header-table">
      {llpResult.getVisibleCells().map((cell) => (
        <td key={cell.id} className="border-3 h-6 min-h-6 truncate border border-black-20 bg-header-table  text-xs">
          <div
            className="h-6 min-h-6 truncate "
            style={{
              width: `${cell.column.getSize()}px`,
              maxWidth: `${cell.column.getSize()}px`,
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        </td>
      ))}
    </tr>
  )
}

export { KbLLpRow }
